body {
  overflow: hidden;
}

.mode_owner {
  display: none;
}

body[mode-owner="true"] .mode_owner {
  display: block;
}

.switch_nav {
  cursor: pointer;
  align-items: center;
  gap: 7px;
  margin-right: 15px;
  line-height: 1;
  display: flex;
}

.switch_nav label {
  color: var(--dark-color) !important;
  margin-bottom: 0 !important;
}

.switch {
  width: 30px;
  height: 17px;
  display: inline-block;
  position: relative;
  color: var(--dark-color) !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 17px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(13px);
}

nav {
  z-index: 2;
  border-bottom: 1px solid var(--light-color);
  background: #fff;
  height: 54px;
  padding: 0 35px;
  display: flex;
  position: relative;
}

.nav_logo {
  flex: 1;
  align-items: center;
  padding: 10px 35px 20px 0;
  display: flex;
}

.user_logged .nav_logo {
  flex: 0;
}

.nav_system a {
  font-size: .95rem !important;
}

.nav_lang {
  margin-right: 10px;
  display: flex;
}

.nav_lang a {
  padding: 2px;
}

.nav_lang a:first-of-type:after {
  content: "/";
  padding-left: 4px;
  position: relative;
}

.nav_logo .logo {
  width: 100px;
}

.nav_menu, .nav_system {
  ajustify-content: center;
  text-align: center;
  align-items: center;
  margin: 0;
  font-size: 0;
  display: flex;
}

.nav_menu {
  flex: 1;
  gap: 10px;
}

.nav_system {
  aborder-left: 1px solid var(--gray-color);
  gap: 0;
}

nav a {
  color: var(--dark-color);
  afont-weight: 600;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 1.1rem;
  transition: all .25s;
  display: flex;
}

.nav_menu a.selected {
  background: var(--primarylight-color);
  acolor: var(--primary-color);
  outline: none;
}

.nav_manu a:hover {
  color: var(--light-color);
  text-decoration: none;
}

.login_section {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.login_logos {
  display: flex;
}

.login_logos .col:last-child {
  text-align: right;
}

.login_section .logo {
  width: 150px;
}

.login_section .logo_130 {
  width: 130px;
}

.login_subtitle {
  margin-bottom: 5px;
  font-weight: 700;
}

.login_section .btn_default {
  width: auto;
}

.login_section .btn_default.disabled {
  color: #999;
  pointer-events: none;
}

.login_frame {
  background: var(--light-color);
  border: 1px solid var(--gray-color);
  min-width: 350px;
  max-width: 800px;
  margin-bottom: 50px;
  box-shadow: 0 10px 15px -3px #0000004d;
}

.login_frame.closed {
  opacity: 0;
  display: none;
}

#section_register_pharm .login_frame {
  padding: 20px 40px 40px;
}

.login_top {
  margin-bottom: 20px;
  padding: 20px 30px 10px;
}

.login_title {
  font-size: 1.5rem;
  font-weight: 800;
}

.farmaval_version {
  font-size: .75rem;
}

.login_content {
  padding: 0 30px 30px;
}

.login_content form {
  margin-bottom: 70px;
}

.login_section input {
  border: none;
  border-bottom: 1px solid var(--gray-color);
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 0;
  font-size: 1em;
  display: block;
}

.login_error {
  color: red;
  abackground: var(--background);
  acolor: var(--gray-color);
  apadding: 5px 10px;
  margin-bottom: 30px;
}

.update_password_section .login_frame {
  amargin-bottom: 10px;
  padding: 40px 40px 50px;
}

.update_password_section ul {
  margin-bottom: 0;
}

.update_password_section .row {
  gap: 10px;
  align-items: top;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
}

.update_password_section .login_content {
  flex-direction: column;
  justify-content: end;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
}

.update_password_section .login_content form {
  margin-bottom: 40px;
}

.update_password_section ul {
  padding-left: 0;
}

.update_password_section li {
  align-items: baseline;
  gap: 5px;
  margin-bottom: 5px;
  display: flex;
}

.update_password_section .check {
  border: 1px solid gray;
  width: 16px;
  height: 16px;
  position: relative;
}

.update_password_section .check svg {
  fill: green;
  width: 12px;
  height: 12px;
  display: none;
  position: absolute;
  top: 1px;
  left: 1px;
}

.update_password_section li.valid svg {
  display: inline;
}

.update_password_section .login_error {
  margin: 0;
}

.login_frame.small {
  max-width: 400px;
  padding: 30px 40px !important;
}

.login_frame.small .btn_default {
  margin-top: 30px;
}

.col_btn {
  color: var(--light-color);
  position: absolute;
  right: -80px;
  background: var(--warning-color) !important;
}

.tr.selected .col_btn {
  right: 0;
}

.server_error {
  border-left: 4px solid var(--warningdark-color);
  padding: 0 10px;
}

.server_error.right {
  text-align: right;
  border-left: none;
  border-right: 4px solid var(--warningdark-color);
}

.server_ok {
  border-color: var(--primary-color);
}

input.novalid:not(:valid), select.novalid:not(:valid), textarea.novalid:not(:valid) {
  border-bottom-color: var(--warningdark-color);
  border-bottom-width: 2px;
}

.operation_confirm {
  width: 100%;
  margin: 0 0 20px;
}

.operation_confirm input {
  margin-top: 5px;
}

.form.add .just_add {
  display: block;
}

.form.add .just_update, .form.edit .just_add {
  display: none;
}

.form.edit .just_update {
  display: block;
}

.form_footer {
  justify-content: end;
  align-items: center;
  margin-top: 40px;
}

.field_unit {
  align-items: center;
  display: flex;
}

.field_unit .unit {
  background: var(--background);
  border: 1px solid var(--gray-color);
  flex: 1;
  align-items: center;
  height: 32px;
  padding: 0 5px;
  display: flex;
}

.popup_signature {
  z-index: 100;
  background: #00000080;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.signature_canvas {
  background: var(--light-color);
  aspect-ratio: 3;
  width: 50%;
  min-width: 400px;
}

.signature_footer {
  border-top: 1px solid var(--gray-color);
  background: var(--background);
  gap: 10px;
  width: 50%;
  min-width: 400px;
  padding: 15px 20px;
  display: flex;
}

.pharm_image_select {
  display: block;
  position: relative;
}

.pharm_image_select img[src] + .label_inside {
  display: none;
}

.pharm_image {
  aspect-ratio: 18 / 6;
  object-fit: cover;
  border: 1px solid var(--gray-color);
  background: var(--light-color);
  width: 100%;
}

.modal {
  z-index: 100;
  align-items: center;
  justify-content: top;
  background: #00000080;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.visible {
  display: block;
}

.modal.error .modal_top {
  background: var(--warningdark-color);
}

.modal_btn_cancel {
  display: none;
}

.modal.input_box .modal_btn_cancel {
  display: block;
}

.modal_window {
  background: #fff;
  border-radius: 10px;
  max-width: 400px;
  margin: 10vh auto 0;
  padding-bottom: 20px;
}

.modal_top {
  background: var(--primary-color);
  color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 10px 20px;
}

.modal_body {
  padding: 20px;
}

.modal_title {
  font-weight: 600;
}

.modal_msg {
  margin-bottom: 15px;
}

.modal_input {
  display: none;
}

.modal.input_box .modal_input {
  display: block;
}

.modal_footer {
  abackground: #f0f0f0;
  border-top: 1px solid #f0f0f0;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding: 20px 20px 0;
  display: flex;
}

.modal_extra {
  margin-top: 20px;
}

.modal_extra_link {
  color: var(--primary-color);
  text-decoration: none;
}

.modal_extra_info {
  color: var(--gray-color);
}

.popup {
  width: 100%;
  height: 100%;
  overflow: none;
  visibility: hidden;
  transition: all .75s;
  position: fixed;
  top: 0;
  left: 0;
}

.popup_cover {
  background: none;
  width: 100%;
  height: 100%;
  transition: all .25s;
  position: absolute;
  top: 0;
  left: 0;
}

.popup_window {
  background: var(--background);
  border-left: 1px solid var(--gray-color);
  flex-direction: column;
  min-width: 500px;
  height: 100%;
  transition: all .75s;
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
  overflow-y: auto;
}

#popup_report .popup_window {
  min-width: 650px;
}

#popup_patient .popup_window {
  min-width: 550px;
}

.popup.visible {
  visibility: visible;
}

.popup.visible .popup_cover {
  background: #00000080;
}

.popup.visible .popup_window {
  transform: translateX(-100%);
}

.popup_top .close_popup {
  fill: var(--light-color);
  margin: 5px 0;
  line-height: 0;
}

.popup_top {
  background: var(--primary-color);
  fill: var(--light-color);
  color: var(--light-color);
  align-items: center;
  padding: 5px 10px;
}

.popup_title {
  text-transform: none;
  font-size: 1rem;
  font-weight: 600;
}

.popup_content {
  flex: 1;
  padding: 20px 20px 30px;
}

.popup_sep {
  margin-bottom: 15px;
}

.popup_footer {
  justify-content: end;
  align-items: center;
  padding: 0 20px 30px;
  display: flex;
}

.popup_list {
  background: var(--light-color);
  height: 100px;
  padding: 10px;
  overflow-y: scroll;
}

.content {
  z-index: 4;
  width: 100%;
  height: calc(100vh - 54px);
  margin-top: 54px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

section {
  padding: 15px 35px 80px;
  display: none;
}

section.selected {
  display: block;
}

section.no_submenu {
  padding-top: 50px;
}

.tabs_top {
  align-items: baseline;
  display: flex;
}

.tabs_top label {
  color: var(--primary-color);
  font-weight: 800;
}

.popup .tabs_top {
  background: #fff;
  margin-bottom: 10px;
  padding-top: 10px;
}

.tab_btn {
  color: var(--dark-color);
  border-bottom: 3px solid #0000;
  padding: 0 20px 10px;
  font-weight: 600;
  transition: all .25s;
}

.tab_btn.selected {
  border-bottom: 3px solid var(--primary-color);
  color: var(--primary-color);
}

.tabs_content {
  background: var(--light-color);
  border-top: none;
  border: 1px solid var(--gray-color);
  min-height: calc(100vh - 200px);
  padding: 30px 30px 40px;
}

.row_select {
  color: inherit;
}

.row_select .td {
  transition: all .25s;
}

.row_select:hover .td {
  abackground: var(--hover-color);
}

.row_select.selected .td {
  background: var(--highlight-color);
}

.formation_label {
  color: var(--gray-color);
  margin-bottom: 5px;
}

.formation_type_row {
  flex-wrap: wrap;
  align-items: baseline;
  gap: 40px;
  margin-bottom: 30px;
}

.formation_type {
  color: inherit;
  border-bottom: 2px solid #0000;
  padding-bottom: 2px;
  transition: all .25s;
}

.formation_type.selected {
  border-color: var(--primary-color);
}

.formation_sections {
  gap: 20px;
  display: flex;
}

.videos_list {
  margin-bottom: 50px;
  padding-left: 10px;
}

.video_link, .material_link {
  color: var(--dark-color);
  padding: 5px;
  display: block;
}

.video_link {
  width: fit-content;
  margin-left: 30px;
  display: list-item;
}

.material_link {
  align-items: start;
  gap: 5px;
  margin-left: 10px;
  display: flex;
}

.material_link img {
  width: 24px;
}

.video_link.selected {
  background: var(--primarylight-color);
}

.video_frame {
  aposition: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: sticky;
  top: 50px;
  overflow: hidden;
}

.video_iframe iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.training_content {
  align-items: top;
  flex-wrap: wrap;
  gap: 40px;
  margin: 10px 20px;
  display: flex;
}

.training_item {
  border: 1px solid var(--secondary-color);
  width: calc(25% - 30px);
  min-width: 230px;
  max-width: 300px;
  color: var(--dark-color);
  background: var(--secondary-color);
  border-radius: 10px;
  padding: 10px;
  transition: all .5s;
  display: block;
}

.training_item:hover {
  background: var(--primarylight-color);
}

.training_item.back svg {
  adisplay: block;
  amargin: 0 auto;
  awidth: 60px;
}

.training_item:hover {
  color: var(--dark-color);
}

.training_item img {
  aspect-ratio: 1;
  background: var(--primarylight-color);
  apadding: 10px;
  width: 100%;
  display: block;
}

.training_item .info {
  padding: 10px 15px 20px;
}

.training_item .title {
  font-weight: 800;
}

.training_grid .videos_list {
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 30px;
  padding-left: 0;
  display: flex;
}

.training_grid .material_list {
  margin-top: 22px;
}

.training_grid .material_link {
  margin-left: 0;
  padding-left: 0;
}

.col_videos {
  flex: 3;
}

.col_material {
  awidth: 300px;
  flex: 1;
}

.item_video {
  background: var(--secondary-color);
  awidth: 100%;
  width: calc(50% - 20px);
  margin-bottom: 30px;
}

.item_video iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

.item_video_title {
  padding: 10px 15px;
}

#section_videos .tab_btn {
  color: var(--primary-color);
  padding-right: 0;
}

#section_videos .tab_btn:after {
  content: ">";
  color: var(--dark-color);
  padding-left: 20px;
}

#section_videos .tab_btn:last-child {
  color: var(--dark-color);
  pointer-events: none;
}

#section_videos .tab_btn:last-child:after {
  content: "";
}

.services_list {
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50%;
  display: grid;
}

.service_item {
  background: var(--light-color);
  border: 1px solid var(--gray-color);
  fill: var(--sati-color);
  color: var(--sati-color);
  padding: 20px;
  display: flex;
}

.service_item.iretia {
  fill: var(--iretia-color);
  color: var(--iretia-color);
}

.service_item.cepoc {
  fill: var(--cepoc-color);
  color: var(--cepoc-color);
}

.service_item.probio {
  fill: var(--probio-color);
  color: var(--probio-color);
}

.service_item:hover {
  border: 1px solid var(--sati-color);
}

.service_item.iretia:hover {
  border: 1px solid var(--iretia-color);
}

.service_item.cepoc:hover {
  border: 1px solid var(--cepoc-color);
}

.service_item.probio:hover {
  border: 1px solid var(--probio-color);
}

.service_item svg {
  afill: var(--dark-color);
  background: var(--background);
  border: 1px solid var(--gray-color);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-right: 40px;
  transition: all .25s;
  display: block;
}

.service_info {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.service_title {
  acolor: var(--dark-color);
  font-size: 1.5rem;
  font-weight: 800;
  transition: all .25s;
}

.service_item:hover .service_title {
  color: var(--sati-color);
}

.service_item.iretia:hover .service_title {
  color: var(--iretia-color);
}

.service_item.cepoc:hover .service_title {
  color: var(--cepoc-color);
}

.service_item.probio:hover .service_title {
  color: var(--probio-color);
}

.service_subtitle {
  color: var(--gray);
  margin-bottom: 20px;
  font-size: 1.35rem;
  line-height: 1.2;
}

.service_item:hover svg {
  fill: var(--sati-color);
  border-color: var(--sati-color);
}

.service_item.iretia:hover svg {
  fill: var(--iretia-color);
  border-color: var(--iretia-color);
}

.service_item.cepoc:hover svg {
  fill: var(--cepoc-color);
  border-color: var(--cepoc-color);
}

.service_item.probio:hover svg {
  fill: var(--probio-color);
  border-color: var(--probio-color);
}

.service_item.disabled .disabled_tag {
  display: block;
}

.disabled_tag {
  background: var(--gray-color);
  width: fit-content;
  color: var(--light-color);
  border-radius: 15px;
  padding: 3px 10px;
  display: none;
}

.disabled_row {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.disabled_col {
  flex: 1;
  gap: 1rem;
  display: flex;
}

.disabled_link {
  border: 1px solid var(--probio-color);
  border-radius: .5rem;
  width: fit-content;
  padding: .5rem .75rem;
}

.service_iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.row_filter_reports {
  gap: 20px;
  margin-bottom: 10px;
  padding: 5px 0;
}

.popup_iframe {
  z-index: 12000;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.popup_iframe iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#table_sales {
  margin-top: 5px;
}

.report_stats {
  margin-top: 20px;
}

.report_stats iframe {
  border: none;
  width: 100%;
}

#popup_date.add .btn_date_save_add {
  display: block;
}

#popup_date.add .btn_date_save_update, #popup_date.add #btn_date_delete, #popup_date.edit .btn_date_save_add {
  display: none;
}

#popup_date.edit .btn_date_save_update, #popup_date.edit #btn_date_delete {
  display: block;
}

.calendar_dates_top {
  align-items: center;
  height: 53px;
}

.calendar_dates_top .row {
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
  font-weight: 800;
}

.calendar_dates_top small {
  font-size: 1rem;
  font-weight: 400;
}

.pending_list {
  margin-top: 30px;
}

.pending_list li {
  margin-bottom: 10px;
}

.pending_agreement_document {
  font-weight: 600;
}

#syncro_services_wrapper.disabled #btn_syncro_now, #syncro_services_wrapper.disabled #table_cache_services, .table_dummie {
  display: none;
}

#syncro_services_wrapper.disabled .table_dummie {
  display: table;
}

.table_syncro {
  margin: 5px 0 30px;
}

.backup_row .result {
  white-space: nowrap;
  width: 1%;
  display: none;
}

.backup_row.error .result {
  color: var(--warningdark-color);
  display: table-cell;
}

.backup_row.done .result {
  display: table-cell;
}
/*# sourceMappingURL=path0.236564d5.css.map */

body 
{
	overflow:hidden;
}

.mode_owner 
{
	display:none;
}
body[mode-owner="true"] .mode_owner
{
	display:block;
}
/* The switch - the box around the slider */
.switch_nav 
{
	margin-right:15px;
	display:flex;
	gap:7px;
	align-items: center;
	line-height: 1;
	cursor: pointer;
}
.switch_nav label{
	color:var(--dark-color) !important;
	margin-bottom:0 !important;
}
.switch {
	color:var(--dark-color) !important;
	position: relative;
	display: inline-block;
	width: 30px;
	height: 17px;
  }
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 17px;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 50%;  
}
  
  input:checked + .slider {
	background-color: var(--primary-color);
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px var(--primary-color);
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(13px);
	-ms-transform: translateX(13px);
	transform: translateX(13px);
  }
  

/* nav */
nav
{
	height:54px;
	background:white;
	padding:0px 35px 0 35px;
	display:flex;
	z-index:2;
	position:relative;
	border-bottom:1px solid var(--light-color);
}
.nav_logo
{
    display: flex;
	flex:1;
    align-items: center;
    padding: 10px 35px 20px 0;
}
.user_logged .nav_logo
{
	flex:0;
}
.nav_system a
{
	font-size:0.95rem !important;
}
.nav_lang 
{
	display:flex;
	margin-right:10px;
}
.nav_lang a 
{
	padding:2px;
}
.nav_lang a:first-of-type::after
{
	position:relative;
	content:"/";
	padding-left:4px;
} 

.nav_logo .logo
{
	width:100px;	
}
.nav_menu, .nav_system
{
	display:flex;
	align-items:center;
	ajustify-content: center;
	margin:0;
	text-align:center;
	font-size:0;
}
.nav_menu
{
	flex:1;
	gap:10px;
}
.nav_system 
{
	gap:0;
	aborder-left:1px solid var(--gray-color);
}
nav a
{
	color:var(--dark-color);
	display:block;
	padding:5px 15px;
	afont-weight:600;
	font-size:1.1rem;	
	display:flex;
	align-items:center;
	justify-content:center;
	transition: all 0.25s;
	border-radius:1rem;
}
.nav_menu a.selected
{
	background:var(--primarylight-color);
	acolor:var(--primary-color);
	outline:none;
}
.nav_manu a:hover
{
	color:var(--light-color);
	text-decoration:none;	
}

/* --- Login / signup --- */
.login_section
{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:0;
}

.login_logos 
{
	display:flex;
}
.login_logos .col:last-child
{
	text-align: right;
}
.login_section .logo 
{
	width:150px;
}
.login_section .logo_130 
{
	width:130px;
}
.login_subtitle 
{
	font-weight:700;
	margin-bottom:5px;
}


.login_section .btn_default 
{
	width:auto;
}  
.login_section .btn_default.disabled 
{
	color:#999;
	pointer-events: none;
}
.login_frame
{
	background:var(--light-color);
	border:1px solid var(--gray-color);
	max-width:800px;
	min-width:350px;
	box-shadow:0 10px 15px -3px #0000004d;
	margin-bottom:50px;

	/*display:block;
	position: relative;
	transition: all 0.5s;
	transition-behavior: allow-discrete !important;
	opacity: 1;
	*/
}

.login_frame.closed 
{ 
	display: none;
	opacity: 0;
}

#section_register_pharm .login_frame 
{
	padding:20px 40px 40px 40px;
}

.login_top 
{
	margin-bottom:20px;
	padding:20px 30px 10px 30px;
}
.login_title 
{
	font-size:1.5rem;
	font-weight: 800;
}
.farmaval_version 
{
	font-size:0.75rem;
}

.login_content 
{
	padding:0 30px 30px 30px;
}
.login_content form 
{
	margin-bottom:70px;
}
.login_section input 
{
	display:block;
	width:100%;
	margin-bottom:20px;
	font-size:1em;
	outline: none;
	border:none;
	border-bottom:1px solid var(--gray-color);
	padding:5px 0;
}

.login_error 
{
	margin-bottom: 30px;
	color:red;
    abackground: var(--background);
    acolor: var(--gray-color);
    apadding: 5px 10px;
}

.update_password_section .login_frame 
{
	padding:40px 40px 50px 40px;
	amargin-bottom:10px;
}
.update_password_section ul 
{
	margin-bottom:0;
}
.update_password_section .row 
{
	gap:10px;
	padding:0;
	align-items: top;
	margin-bottom:0;
	margin-top:20px;
}
.update_password_section .login_content 
{
	display:flex;
	flex-direction: column;
	justify-content: end;
	padding-bottom:0;
	padding-right:0;
}
.update_password_section .login_content form 
{
	margin-bottom:40px;
}
.update_password_section ul 
{
	padding-left:00px;
}

.update_password_section li
{
	display:flex;
	align-items: baseline;
	gap:5px;
	margin-bottom: 5px;;
}

.update_password_section .check 
{
	border:1px solid gray;
	width:16px;
	height:16px;
	position:relative;
}
.update_password_section .check svg
{
	display:none;
	position:absolute;
	left:1px;
	top:1px;
	width:12px;
	height:12px;
	
	fill:green;
}
.update_password_section li.valid svg
{
	display:inline;
}

.update_password_section .login_error 
{
	margin:0;
}

.login_frame.small 
{
	max-width:400px;
	padding:30px 40px 30px 40px !important;
}
.login_frame.small .btn_default
{
	margin-top:30px;
}




/* --- general --- */


.col_btn 
{
	right:-80px;
	position:absolute;
	background:var(--warning-color) !important;
	color:var(--light-color);
}
.tr.selected .col_btn 
{
	right:0;
}
.server_error 
{
	border-left: 4px solid var(--warningdark-color);
	padding:0 10px;
}
.server_error.right 
{
	text-align: right;
	border-left:none;
	border-right: 4px solid var(--warningdark-color);
}
.server_ok 
{
	border-color: var(--primary-color);
}
input.novalid:not(:valid),
select.novalid:not(:valid),
textarea.novalid:not(:valid)
{
	border-bottom-color:var(--warningdark-color);
	border-bottom-width:2px;
}

.operation_confirm
{
	width:100%;
	margin:0px 0 20px 0;
}
.operation_confirm input 
{
	margin-top:5px;
}

.form 
{

}
.form.add .just_add
{
	display:block;
}
.form.add .just_update
{
	display:none;
}
.form.edit .just_add
{
	display:none;
}
.form.edit .just_update
{
	display:block;
}

.form_footer 
{
	margin-top:40px;
	justify-content: end;
	align-items:center;
}
.field_unit 
{
	display:flex;
	align-items: center;
}
.field_unit .unit 
{
	flex:1;
	background:var(--background);
	height:32px;
	display:flex;
	align-items: center;
	padding:0 5px;
	border:1px solid var(--gray-color);
}

/* --- signatures --- */

.popup_signature
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:100;
	background:rgba(0,0,0,0.5);
	display:flex;
	align-items:center;
	justify-content: center;
	flex-direction:column;
}
.signature_canvas 
{
	background:var(--light-color);
	aspect-ratio: 3/1;
	width:50%;
	min-width:400px;
}
.signature_footer 
{
	display:flex;
	border-top:1px solid var(--gray-color);
	gap:10px;
	width:50%;
	min-width:400px;
	padding:15px 20px;
	background:var(--background);
}

/* --- pharm --- */
.pharm_image_select 
{
	position:relative;
	display:block;
}
.pharm_image_select img[src] +  .label_inside 
{
	display:none;
}
.pharm_image
{
	width:100%;
	aspect-ratio:18/6;
	object-fit: cover;
	border:1px solid var(--gray-color);
	background:var(--light-color); 
}

/* --- modal --- */
.modal
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:100;
	background:rgba(0,0,0,0.5);
	display:none;
	align-items:center;
	justify-content: top;
	flex-direction:column;
}
.modal.visible 
{
	display:block
}
.modal.error .modal_top 
{
	background:var(--warningdark-color);
} 
.modal_btn_cancel
{
	display:none;
}
.modal.input_box .modal_btn_cancel 
{
	display:block;
}
.modal_window 
{
	max-width:400px;
	margin:10vh auto 0 auto;
	background:white;
	border-radius:10px;
	padding-bottom:20px;
}
.modal_top 
{
	background:var(--primary-color);
	color:white;
	padding:10px 20px;
	border-radius: 10px 10px 0 0;
}
.modal_body 
{
	padding:20px;
}
.modal_title 
{
	font-weight:600;
}
.modal_msg 
{
	margin-bottom:15px;
}
.modal_input 
{
	display:none;
}
.modal.input_box .modal_input
{
	display:block;
}
.modal_footer 
{
	border-top:1px solid #f0f0f0;
	abackground:#f0f0f0;
	padding:20px 20px 0 20px;
	display:flex;
	align-items: center;
	justify-content:end;
	gap:10px;
}
.modal_extra 
{
	margin-top:20px;
}
.modal_extra_link 
{
	color:var(--primary-color);
	text-decoration:none;
}
.modal_extra_info 
{
	color:var(--gray-color);
}


/* --- popup --- */
.popup
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:none;
	transition:all .75s;
	visibility: hidden;
}
.popup_cover 
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0);
	transition:all 0.25s;
}
.popup_window
{
	position:absolute;
	top:0;
	left:100%;
	height:100%;
	overflow-y:auto;
	min-width:500px;
	background:var(--background);
	border-left:1px solid var(--gray-color);
	transition:all .75s;
	display:flex;
	flex-direction: column;
}
#popup_report .popup_window
{
	min-width:650px;
}
#popup_patient .popup_window
{
	min-width:550px;
}

.popup.visible 
{
	visibility: visible;
}
.popup.visible .popup_cover  
{
	background:rgba(0,0,0,0.5);
}
.popup.visible .popup_window
{
	transform:translateX(-100%);
}

.popup_top .close_popup 
{
	line-height:0;
	margin:5px 0;
	fill: var(--light-color);
}

.popup_top 
{
	background:var(--primary-color);
	fill:var(--light-color);
	color:var(--light-color);
	padding:5px 10px;
	align-items: center;
}
.popup_title 
{
	font-size:1rem;
	font-weight:600;
	text-transform: none;
}
.popup_content 
{
	padding:20px 20px 30px 20px;
	flex:1;
}
.popup_sep 
{
	margin-bottom:15px;
}

.popup_footer 
{
	display:flex;
	align-items: center;
	justify-content: end;
	padding:0 20px 30px 20px;
}
.popup_list 
{
	height:100px;
	background:var(--light-color);
	padding:10px;
	overflow-y:scroll;
}
/* --- sections --- */
.content 
{
	position:absolute;
	margin-top:54px;
	width:100%;
	height:calc( 100vh - 54px );
	top:0;
	left:0;
	overflow-x:hidden;
	overflow-y:auto;
	z-index:4;
}
section 
{
	display:none;
	padding:15px 35px 80px 35px;
}
section.selected
{
	display:block;
}
section.no_submenu 
{
	padding-top:50px;
}
.tabs_top 
{
	display:flex;
	align-items: baseline;
}
.tabs_top label 
{
	color:var(--primary-color);
	font-weight: 800;
	
}

.popup .tabs_top 
{
	margin-bottom:10px;
	background:white;
	padding-top:10px;
	
}
.tab_btn 
{
	border-bottom:3px solid transparent;
	padding:0 20px 10px 20px;
	color:var(--dark-color);
	transition:all 0.25s;
	font-weight:600;
}
.tab_btn.selected
{
	border-bottom:3px solid var(--primary-color);
	color:var(--primary-color);
}
.tabs_content 
{
	padding:30px 30px 40px 30px;
	border-top:none;
	background:var(--light-color);
	min-height: calc(100vh - 200px);
    border: 1px solid var(--gray-color);
}

/* --- rows ---- */
.row_select 
{
	color:inherit;
}
.row_select .td 
{
	transition:all 0.25s;
}
.row_select:hover .td
{
	abackground:var(--hover-color);
}
.row_select.selected .td
{
	background:var(--highlight-color);
}

/* --- formation --- */
.formation_label 
{
	color:var(--gray-color);
	margin-bottom:5px;
}

.formation_type_row 
{
	align-items: baseline;
    gap: 40px;
    margin-bottom: 30px;
	flex-wrap: wrap;
}
.formation_type 
{
	color:inherit;
	padding-bottom:2px;
	border-bottom:2px solid transparent;
	transition:all 0.25s;
}
.formation_type.selected 
{
	border-color:var(--primary-color);
}
.formation_sections 
{
	display:flex;
	gap:20px;
}
.videos_list 
{
	padding-left:10px;
	margin-bottom:50px;
}
.video_link, .material_link
{
	display:block;
	color:var(--dark-color);
	padding:5px 5px;
	
}
.video_link 
{
	display:list-item;
	margin-left:30px;
	width: fit-content;
}
.material_link 
{
	display:flex;
	gap:5px;
	margin-left:10px;
	align-items: start;
}
.material_link img 
{
	width:24px;
}
.video_link.selected 
{
	background:var(--primarylight-color);
}
.video_frame 
{
	position:sticky;
	top:50px;

	aposition: relative;
    width: 100%; /* El ancho del contenedor será 100% */
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 (360 / 640 * 100) */
    height: 0; /* Altura inicial 0 para que el padding-bottom funcione */
    overflow: hidden; /* Evitar desbordamiento */
}


.video_iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* El iframe ocupa el ancho completo */
    height: 100%; /* La altura se ajusta automáticamente para mantener la proporción */
    border: 0;
}

.training_content 
{
	display:flex;
	align-items: top;
	flex-wrap: wrap;
	gap:40px;
	margin:10px 20px;
}
.training_item 
{
	width:calc(25% - 30px);
	max-width:300px;
	border:1px solid var(--secondary-color);
	min-width:230px;
	display:block;
	color:var(--dark-color);
	transition:all 0.5s;
	background:var(--secondary-color);
	padding:10px;
	border-radius: 10px;;
}
.training_item:hover 
{
	background:var(--primarylight-color);
}
.training_item.back svg 
{
	adisplay:block;
	amargin:0 auto;
	awidth:60px;
}
.training_item:hover 
{
	color:var(--dark-color);
}
.training_item img 
{
	width:100%;
	aspect-ratio: 1/1;
	background:var(--primarylight-color);
	display:block;
	
	apadding:10px;
}
.training_item .info
{
	padding:10px 15px 20px 15px;
}
.training_item .title 
{
	font-weight:800;
}
.training_item .author
{

}

.training_grid .videos_list 
{
	display:flex;
	gap:40px;
	flex-wrap: wrap;
	margin-top:30px;
	padding-left:0;
}
.training_grid .material_list 
{
	margin-top:22px;
}
.training_grid .material_link 
{
	margin-left:0;
	padding-left:0;
}
.col_videos 
{
	flex:3;
}
.col_material 
{
	awidth:300px;
	flex:1;
}
.item_video 
{
	width:calc(50% - 20px);
	margin-bottom:30px;
	background:var(--secondary-color);
	awidth:100%;
}
.item_video iframe 
{
	width:100%;
	height:auto;
	aspect-ratio: 16/9;
}
.item_video_title
{

	padding:10px 15px;
}

#section_videos .tab_btn
{
	padding-right:0px;
	color:var(--primary-color);
}
#section_videos .tab_btn:after
{
	content:">";
	padding-left:20px;
	color:var(--dark-color);
}

#section_videos .tab_btn:last-child
{
	color:var(--dark-color);
	pointer-events: none;
}
#section_videos .tab_btn:last-child::after
{
	content:'';
}
/* --- services --- */

.services_list
{
	display: grid;
    grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 50%;
	grid-gap: 20px;
}
.service_col 
{
	
}
.service_item 
{
	background:var(--light-color);
	display:block;
	padding:20px;
	display:flex;
	border:1px solid var(--gray-color);
}
.service_item 
{
	fill:var(--sati-color);
	color:var(--sati-color);
}
.service_item.iretia
{
	fill:var(--iretia-color);
	color:var(--iretia-color);
}
.service_item.cepoc
{
	fill:var(--cepoc-color);
	color:var(--cepoc-color);
}
.service_item.probio
{
	fill:var(--probio-color);
	color:var(--probio-color);
}
.service_item:hover 
{
	border:1px solid var(--sati-color);
}
.service_item.iretia:hover 
{
	border:1px solid var(--iretia-color);
}
.service_item.cepoc:hover 
{
	border:1px solid var(--cepoc-color);
}
.service_item.probio:hover 
{
	border:1px solid var(--probio-color);
}
.service_item svg 
{
	width:150px;
	height:150px;
	display:block;
	border-radius:50%;
	afill:var(--dark-color);
	background:var(--background);
	margin-right:40px;
	border:1px solid var(--gray-color);
	transition: all .25s;
}
.service_info 
{
	flex:1;
	display:flex;
	flex-direction: column;
	justify-content: center;
}
.service_title 
{
	font-size:1.5rem;
	font-weight:800;
	acolor: var(--dark-color);
	transition: all .25s;
}
.service_item:hover .service_title 
{
	color:var(--sati-color);
}
.service_item.iretia:hover .service_title 
{
	color:var(--iretia-color);
}
.service_item.cepoc:hover .service_title 
{
	color:var(--cepoc-color);
}
.service_item.probio:hover .service_title 
{
	color:var(--probio-color);
}
.service_subtitle 
{
	font-size:1.35rem;
	color:var(--gray);
	line-height:1.2;
	margin-bottom:20px;
}
.service_item:hover svg 
{
	fill: var(--sati-color);
	border-color:var(--sati-color);
}
.service_item.iretia:hover svg 
{
	fill: var(--iretia-color);
	border-color:var(--iretia-color);
}
.service_item.cepoc:hover svg 
{
	fill: var(--cepoc-color);
	border-color:var(--cepoc-color);
}
.service_item.probio:hover svg 
{
	fill: var(--probio-color);
	border-color:var(--probio-color);
}
.service_item.disabled .disabled_tag
{
	display:block;
}

.disabled_tag 
{
	display:none;
	width:fit-content;
	padding:3px 10px;
	background:var(--gray-color);
	border-radius:15px;
	color:var(--light-color);
}
.disabled_row 
{
	display:flex;
	gap:2rem;
	align-items: center;
}
.disabled_col 
{
	flex:1;
	display:flex;
	gap:1rem;
}
.disabled_link 
{
	border:1px solid var(--probio-color);
	border-radius:0.5rem;
	padding:0.5rem 0.75rem;
	width:fit-content;
}

/*
.disabled_tag.probio 
{
	background:var(--probio-color);
	padding:0.25rem 0.75rem;
}
*/

.service_iframe 
{
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	border:none;
}


/* --- reports --- */
.row_filter_reports 
{
	padding:5px 0;
	gap:20px;
	margin-bottom:10px;
}
.popup_iframe
{
	position:fixed;
	z-index:12000;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);	
}
.popup_iframe iframe
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;	
}
#table_sales 
{
	margin-top:5px;
}

.report_stats 
{
	margin-top:20px;
}
.report_stats iframe 
{
	width:100%;
	border:none;
}

/* agenda */

#popup_date.add .btn_date_save_add
{
	display:block;
}
#popup_date.add .btn_date_save_update,
#popup_date.add #btn_date_delete
{
	display:none;
}
#popup_date.edit .btn_date_save_add
{
	display:none;
}
#popup_date.edit .btn_date_save_update,
#popup_date.edit #btn_date_delete
{
	display:block;
}

.calendar_dates_top
{
	height:53px;
	align-items:center;
}
.calendar_dates_top .row 
{
	gap:10px;
	align-items: center;
	font-size: 1.5rem;
    font-weight: 800;
}
.calendar_dates_top small 
{
	font-size:1rem;
	font-weight:400;
}

/* Pending */
.pending_list 
{
	margin-top:30px;
}
.pending_list li 
{
	margin-bottom:10px;
}
.pending_agreement_document 
{
	font-weight:600;
}
/* syncr */
#syncro_services_wrapper.disabled #btn_syncro_now
{
	display:none;
}

#syncro_services_wrapper.disabled #table_cache_services
{
	display:none;
}

.table_dummie 
{
	display:none;
}

#syncro_services_wrapper.disabled .table_dummie
{
	display:table;
}

.table_syncro 
{
	margin:5px 0 30px 0;
}

.backup_row .result 
{
	display:none;
	
	width: 1%;
    white-space: nowrap;
}
.backup_row.error .result
{
	color:var(--warningdark-color);
	display:table-cell;
}
.backup_row.done .result
{
	display:table-cell;
}

